import React from 'react';
import styles from './Page3.module.css';

function Page3() {
  return (
    <div className="page">
      
      <h1>ACCESS TO WHALE CHAT</h1>
      <p>if you have bought $1,500 worth of $hmmm and would like to join whale chat please reach out to an admin in our TG group. proof is required for entry.</p>
      <button onClick={() => window.location.href = 'https://t.me/hmmmSOLANA'}>CONTACT</button>
    </div>
  );
}

export default Page3;



