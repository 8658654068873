import React, { useState, useRef } from 'react';

function Page2() {
  const [imageSrc, setImageSrc] = useState(null); // Store uploaded image
  const canvasRef = useRef(null); // Reference for the canvas
  const characterRef = useRef(null); // Reference for the character image

  // Handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Generate the final meme image with the overlay
  const handleDownload = () => {
    if (canvasRef.current && imageSrc) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Create an image element for the uploaded image
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        // Set canvas size to the uploaded image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the uploaded image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get the character image (logo)
        const characterImg = characterRef.current;

        // Calculate the logo size as 25% of the uploaded image's width (increased size)
        const logoSize = img.width * 0.25;

        // Draw the logo in the bottom-left corner with no padding
        ctx.drawImage(characterImg, 0, canvas.height - logoSize, logoSize, logoSize); // Position logo flush in the corner

        // Create a downloadable link
        const link = document.createElement('a');
        link.href = canvas.toDataURL(); // Convert canvas to image
        link.download = 'meme.png'; // Set the filename for download
        link.click(); // Trigger the download
      };
    }
  };

  return (
    <div className="page" style={{ textAlign: 'center' }}>
      {/* New Image Above the Text */}
      <img
        src="/logo.png" // Replace with the correct path to your image
        alt=""
        style={{ width: '400px', height: '400px', objectFit: 'cover', marginBottom: '20px' }}
      />
      
      <h1>HMMMEME MAKER</h1>
      
      {/* File input for image upload */}
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      
      {/* Download Button */}
      {imageSrc && (
        <button onClick={handleDownload} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
          Download Meme
        </button>
      )}

      {/* Hidden Canvas for rendering the final meme image */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      
      {/* Logo (character PNG) */}
      <img
        ref={characterRef}
        src="/meme.png" // Replace with correct path
        alt="Hmmm character"
        style={{ display: 'none' }} // Hidden, only used for drawing on canvas
      />
    </div>
  );
}

export default Page2;
