import React, { useState } from 'react';
import styles from './Page1.module.css';
import html2canvas from 'html2canvas';

const Page1 = () => {
  const [selectedBackground, setSelectedBackground] = useState('/assets/green.PNG');
  const [selectedBody, setSelectedBody] = useState('/assets/body1.PNG');
  const [selectedItem, setSelectedItem] = useState('/assets/joint.PNG');
  const [selectedGlasses, setSelectedGlasses] = useState('assets/thug.PNG');
  const [selectedHat, setSelectedHat] = useState('/assets/blank.PNG');

  const backgrounds = [
    { src: '/assets/green.PNG', label: 'Green' },
    { src: '/assets/blue.PNG', label: 'Blue' },
    { src: '/assets/red.PNG', label: 'Red' },
    { src: '/assets/yellow.PNG', label: 'Yellow' },
    { src: '/assets/purple.PNG', label: 'Purple' },
    { src: '/assets/blank.PNG', label: 'Transparent' },
  ];

  const bodies = [
    { src: '/assets/body1.PNG', label: 'Body 1' },
  ];

  const items = [
    { src: '/assets/joint.PNG', label: 'Joint' },
    { src: '/assets/cigar.PNG', label: 'Cigar' },
    { src: '/assets/spoon.PNG', label: 'Spoon' },
    { src: '/assets/sword.PNG', label: 'Sword' },
    { src: '/assets/blank.PNG', label: 'None' },
    
  ];

  const glasses = [
    { src: '/assets/thug.PNG', label: 'Thug' },
    { src: '/assets/pitvipers.PNG', label: 'Pit Vipers' },
    { src: '/assets/blank.PNG', label: 'None' },

  ];

  const hats = [
    { src: '/assets/chef.PNG', label: 'Chef' },
    { src: '/assets/pirate.PNG', label: 'Pirate' },
    { src: '/assets/headset.PNG', label: 'Headset' },
    { src: '/assets/blank.PNG', label: 'None' },
  ];

  const downloadImage = () => {
    html2canvas(document.querySelector("#pfp-canvas")).then(canvas => {
      const link = document.createElement('a');
      link.download = 'custom-pfp.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <div className={styles.page}>
      <h1>PFP Maker</h1>

      <div className={styles.pfpCanvas} id="pfp-canvas">
        <img className={styles.background} src={selectedBackground} alt="background" />
        <img className={styles.body} src={selectedBody} alt="body" />
        <img className={styles.item} src={selectedItem} alt="item" />
        <img className={styles.glasses} src={selectedGlasses} alt="glasses" />
        <img className={styles.hat} src={selectedHat} alt="hat" />
      </div>

      <div className={styles.dropdownContainer}>
        <div className={styles.dropdownGroup}>
          <label>Background:</label>
          <select onChange={(e) => setSelectedBackground(e.target.value)} value={selectedBackground}>
            {backgrounds.map((bg, index) => (
              <option key={index} value={bg.src}>{bg.label}</option>
            ))}
          </select>
        </div>

        <div className={styles.dropdownGroup}>
          <label>Body:</label>
          <select onChange={(e) => setSelectedBody(e.target.value)} value={selectedBody}>
            {bodies.map((body, index) => (
              <option key={index} value={body.src}>{body.label}</option>
            ))}
          </select>
        </div>

        <div className={styles.dropdownGroup}>
          <label>Item:</label>
          <select onChange={(e) => setSelectedItem(e.target.value)} value={selectedItem}>
            {items.map((item, index) => (
              <option key={index} value={item.src}>{item.label}</option>
            ))}
          </select>
        </div>

        <div className={styles.dropdownGroup}>
          <label>Glasses:</label>
          <select onChange={(e) => setSelectedGlasses(e.target.value)} value={selectedGlasses}>
            {glasses.map((glasses, index) => (
              <option key={index} value={glasses.src}>{glasses.label}</option>
            ))}
          </select>
        </div>

        <div className={styles.dropdownGroup}>
          <label>Hat:</label>
          <select onChange={(e) => setSelectedHat(e.target.value)} value={selectedHat}>
            {hats.map((hat, index) => (
              <option key={index} value={hat.src}>{hat.label}</option>
            ))}
          </select>
        </div>
      </div>

      <div className={styles.actionButtons}>
        <button onClick={downloadImage}>Download</button>
        <button>Randomize</button>
      </div>
    </div>
  );
};

export default Page1;
