import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'; 
import './App.css';
import HomePage from './HomePage';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';  // Check if we are on the homepage

  return (
    <div className={`App ${isHomePage ? 'home-bg' : 'solid-bg'}`}>
      <div className="home-button">
        <a href="/">$HMMM</a>
      </div>

      {isHomePage && (
        <div className="home-content">
          <h1></h1> {/* Homepage Text */}
          <nav className="nav-show">
            <ul>
              <li><a href="/page1">PFP MAKER</a></li>
              <li><a href="/page2">HMMMEME MAKER</a></li>
              <li><a href="/page3">HMMMILLIONAIRES</a></li>
            </ul>
          </nav>
        </div>
      )}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/page1" element={<Page1 />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/page3" element={<Page3 />} />
      </Routes>

      {/* Conditionally render Footer only on the homepage */}
      {isHomePage && <Footer />}
    </div>
  );
}

function Footer() {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const contractAddress = document.getElementById("contract-address").value;
    
    navigator.clipboard.writeText(contractAddress)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch(err => {
        console.error('Error copying text: ', err);
      });
  };

  return (
    <div className="footer-overlay">
      <div className="footer-content">
        <div className="footer-links">
          <a href="https://t.me/hmmmSOLANA">Telegram</a>
          <a href="https://x.com/hmmmsol">X</a>
          <a href="https://dexscreener.com/solana/cwchfrnrt6x4ewmba5fagoopyxltxhbunt7eln5q41yy">Chart</a>
          <a href="https://jup.ag/swap/SOL-HMMM">Buy</a>
          <a href="https://t.me/hmmmSOLANA">Memes</a>
        </div>
        <div className="contract-address">
          <div className="contract-box">
            <input
              type="text"
              value="7xywcaBrF6vQVyHJ1eM9LdT5Z45EAbLbDgGuh8i5pump"
              readOnly
              id="contract-address"
            />
            <button onClick={copyToClipboard}>
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
